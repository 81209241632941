import { Link } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import * as React from 'react';
export default {
  Link,
  Container,
  Row,
  Col,
  Button,
  FontAwesomeIcon,
  faAngleRight,
  React
};